@import url(https://fonts.googleapis.com/css?family=Roboto:100,400&display=auto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 45px; }

.lds-ellipsis div {
  position: absolute;
  top: 15px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #c2c2c2;
  animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(24px, 0); } }

.top-panel {
  background: #f1f1f1;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 0;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 302px;
  overflow: hidden;
  text-align: center; }

.count-box {
  padding: 10px 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center; }
  .count-box .lds-ellipsis {
    margin: auto; }
  .count-box__number {
    font-size: 21px;
    color: #444; }
  .count-box__text {
    text-transform: uppercase;
    font-size: 11px;
    margin-top: 7.5px;
    color: #888; }

.MuiTypography-h5 {
  color: #444;
  font-weight: 200 !important; }

.dashboard {
  box-shadow: inset 0 0 6vh rgba(0, 0, 0, 0.2);
  background-color: #555;
  min-height: 100vh;
  padding: 70px 3vw; }

.block {
  margin: 10px 1.5%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

@media (min-width: 620px) {
  .dashboard {
    padding: 70px  0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .block {
    width: 47%;
    margin: 10px 1.5%; } }

@media (min-width: 1070px) {
  .dashboard {
    height: 100%; }
  .block {
    max-width: 400px;
    width: 30%;
    margin: 15px 1.6%; } }

.bar-graph {
  margin-top: 30px;
  border-radius: 4px; }
  .bar-graph .lds-ellipsis {
    top: 75px;
    height: 197px; }
  .bar-graph__item {
    text-align: left;
    margin-bottom: 20px; }
    .bar-graph__item:last-child {
      margin-bottom: 0; }
  .bar-graph__month {
    text-transform: uppercase;
    font-size: 11px;
    color: #888; }
  .bar-graph__header {
    display: flex;
    justify-content: space-between; }
  .bar-graph__text {
    color: #888;
    font-size: 11px;
    text-transform: uppercase; }
  .bar-graph__bar {
    margin: 10px 0;
    height: 10px;
    background: #888;
    border-radius: 2px;
    position: relative;
    font-weight: bold; }
    .bar-graph__bar.-inner {
      background: #9b73af; }

h6.logo {
  color: rgba(255, 255, 255, 0.7);
  font-size: 25.2px;
  font-weight: 200;
  text-align: center;
  text-transform: uppercase; }

.header {
  justify-content: center;
  position: relative; }

header.header__container {
  background-color: #4f4659; }

button.hamburger {
  position: absolute;
  left: 10px; }

div.side-menu {
  padding: 20px;
  text-transform: uppercase;
  color: #fff;
  width: 50vw;
  max-width: 300px;
  background-color: #2b2b2b; }

@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 56px !important; } }

.login {
  height: 100vh;
  box-shadow: inset 0 0 6vh rgba(0, 0, 0, 0.2);
  background-color: #555;
  padding: 0 20px; }
  .login__header {
    color: #fff;
    font-weight: 200;
    text-transform: uppercase;
    font-size: 56px;
    padding: 10vh 0;
    margin: 0; }
  .login__form {
    max-width: 400px;
    margin: 0 auto; }

div.snackbar-message {
  background-color: #d32f2f; }

.error__heading {
  margin: auto; }

.bar-graph {
  position: relative; }
  .bar-graph__title {
    color: #444;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px; }
  .bar-graph__goal {
    color: #888;
    font-size: 14px;
    text-transform: uppercase;
    text-align: right; }
  .bar-graph__stats {
    position: absolute;
    top: 4px;
    left: 5px;
    font-size: 11px;
    color: #fff; }
  .bar-graph__bar.-heigher {
    height: 20px; }
    .bar-graph__bar.-heigher.-inner {
      background: #9b73af; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 200; }

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #09d3ac; }

.MuiFormControl-root {
  margin-bottom: 20px !important; }

.MuiCard-root {
  margin-bottom: 20px; }

.MuiCardHeader-root {
  padding-bottom: 0 !important; }

