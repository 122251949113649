h6.logo {
  color: rgba(255, 255, 255, 0.7);
  font-size: $font-lg * 1.2;
  font-weight: 200;
  text-align: center;
  text-transform: uppercase;
}

.header {
  justify-content: center;
  position: relative;
}

header.header__container {
  background-color: #4f4659;
}

button.hamburger {
  position: absolute;
  left: $m-base * 2;
}

div.side-menu {
  padding: $m-base * 4;
  text-transform: uppercase;
  color: $color-white;
  width: 50vw;
  max-width: 300px;
  background-color: #2b2b2b;
}

@media (min-width: 600px) {
.MuiToolbar-regular {
    min-height: 56px !important;
}
}