.dashboard {
  box-shadow: inset 0 0 6vh rgba(0,0,0,.2);
  background-color: #555;
  min-height: 100vh;
  padding: 70px 3vw
}

.block {
  margin: $m-base * 2 1.5%;
  height: fit-content;
}

@media (min-width: 620px) {
  .dashboard {
    padding: 70px  0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .block {
    width: 47%;
    margin: $m-base * 2 1.5%;
    //max-height: 460px;
  }
}

@media (min-width: 1070px) {
  .dashboard {
    height: 100%;
  }
  .block {
    max-width: 400px;
    width: 30%;
    margin: $m-base * 3 1.6%;
  }
}
