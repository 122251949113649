.login {
  height: 100vh;
  box-shadow: inset 0 0 6vh rgba(0,0,0,.2);
  background-color: #555;
  padding: 0 20px;


  &__header {
    color: $color-white;
    font-weight: 200;
    text-transform: uppercase;
    font-size: $font-xl * 2;
    padding: 10vh 0;
    margin: 0;
  }
  
  &__form {
    max-width: 400px;
    margin: 0 auto;
  }
}

div.snackbar-message {
  background-color: #d32f2f;
}
