.bar-graph {
  margin-top: $m-base * 6;
  border-radius: 4px;

  & .lds-ellipsis {
    top: 75px; 
    height: 197px;
  }
  
  &__item {
    text-align: left;
    margin-bottom: $m-base * 4;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__month {
    text-transform: uppercase;
    font-size: $font-sm;
    color: $color-text-secondary;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__text {
    color: $color-text-secondary;
    font-size: $font-sm;
    text-transform: uppercase;
  }

  &__bar {
    margin: $m-base * 2 0;
    height: $m-base * 2;
    background: $color-text-secondary;
    border-radius: 2px;
    position: relative;
    font-weight: bold;
    &.-inner {
      background: #9b73af;
    }
  }
}
