.top-panel {
  background: $color-pale-gray;
  justify-content: space-between;
  padding-left: $m-base * 2;
  padding-right: $m-base * 2;
  padding-bottom: $m-base * 2;
  padding-top: 0;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 302px;
  overflow: hidden;
  text-align: center;

}

.count-box {
  padding: $m-base * 2 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  
  & .lds-ellipsis {
    margin: auto;
  } 

  &__number {
    font-size: $font-lg;
    color:$color-text-primary;
  }

  &__text {
    text-transform: uppercase;
    font-size: $font-sm;
    margin-top: $m-base * 1.5;
    color: #888;
  }
}

.MuiTypography-h5 {
  color: #444;
  font-weight: 200 !important;
}