$m-base : 5px;

$color-text-primary: #444;
$color-text-secondary: #888;
$color-gray: #555;
$color-light-gray: #eee;
$color-pale-gray: #f1f1f1;
$color-light-blue: #eff0ff;
$color-white: #fff;

$font-base: 14px;
$font-sm: 11px;
$font-lg: $font-base * 1.5;
$font-xl: $font-base * 2;