@import url('https://fonts.googleapis.com/css?family=Roboto:100,400&display=auto');
@import 'variables.scss';
@import 'components/Utils/Loader/Loader.scss';
@import 'components/Dashboard/TopPanel/TopPanel.scss';
@import 'components/Dashboard/Dashboard.scss';
@import 'components/Dashboard/NightsAndRevenue/NightsAndRevenue.scss';
@import 'components/Navigation/Navigation.scss';
@import 'components/Login/Login.scss';
@import 'errorBoundaries/ErrorBoundary.scss';
@import 'components/Dashboard/BenchmarkingVsBudgetedGraph/BenchmarkingVsBudgetedGraph.scss';

h1, h2, h3, h4, h5, h6 {
  font-weight: 200;
}

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.MuiFormControl-root {
  margin-bottom: 20px !important;
}

.MuiCard-root {
  margin-bottom: 20px;
}

.MuiCardHeader-root {
  padding-bottom: 0 !important;
}
