.bar-graph {
  position: relative;
  &__title {
    color: $color-text-primary;
    font-size: $font-base;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: $m-base * 2;
  }
  &__goal {
    color: $color-text-secondary;
    font-size: $font-base;
    text-transform: uppercase;
    text-align: right;
  }
  &__stats {
    position: absolute;
    top: 4px;
    left: 5px;
    font-size: $font-sm;
    color: $color-white;
  }
  &__bar {
    &.-heigher {
      height: $m-base * 4;
      &.-inner {
        background: #9b73af;
      }
    }
  }
}
